@import 'common/styles/vars';

.toast {
  &-danger {
    background-color: $danger-bg-light;
  }
  &-success {
    background-color: $success-bg-light;
  }
}
.toast-header {
  &-danger {
    color: #be3535;
  }
  &-success {
    color: #136313;
  }
}
