@import '../../common/styles/vars.scss';

.settings-container {
  height: calc(100vh - 5rem) !important;
  flex-flow: nowrap;
  overflow: hidden;

  #toolbar {
    margin-bottom: -2.2rem;

    .audit-questions-btn {
      z-index: 5;
    }
  }

  .tab {
    color: $perf-secondary-dark;
    &:hover {
      color: $perf-secondary-darker;
    }
  }

  .tab-content {
    overflow-y: auto;
    overflow-x: hidden;

    .score-col {
      max-width: 5rem;
    }

    .action-col {
      max-width: 2rem;

      margin-top: -0.3rem;
      margin-bottom: -0.3rem;

      .action-icon {
        color: $darker;
        background: none;

        height: 2rem;
        width: 2rem;

        border: none;
        border-radius: 0.3rem;

        &:hover,
        &.show {
          background-color: $light !important;
          transition: background-color 0.25s ease-in-out !important;
        }
      }
    }

    .tab-pane {
      .question {
        &:hover {
          cursor: pointer;
          background-color: $lighter;
        }
      }
    }
  }
}

.question-form {
  // Remove up and down arrows from number input
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
