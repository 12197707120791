$bg-body: #f4f4f4 !important;
$bg-overlay: #2c323aa6;

$perf-primary: #201e24;
$perf-primary-darkest: darken($perf-primary, 25%);
$perf-primary-darker: darken($perf-primary, 10%);
$perf-primary-dark: darken($perf-primary, 5%);
$perf-primary-light: lighten($perf-primary, 5%);
$perf-primary-lighter: lighten($perf-primary, 10%);
$perf-primary-lightest: lighten($perf-primary, 25%);

$perf-secondary: #926bc9;
$perf-secondary-darkest: darken($perf-secondary, 25%);
$perf-secondary-darker: darken($perf-secondary, 10%);
$perf-secondary-dark: darken($perf-secondary, 5%);
$perf-secondary-light: lighten($perf-secondary, 5%);
$perf-secondary-lighter: lighten($perf-secondary, 10%);
$perf-secondary-lightest: lighten($perf-secondary, 25%);
$perf-secondary-hover: lighten($perf-secondary, 35%);

$perf-secondary-disabled: #e7e0f2;
$perf-seconeary-disabled-text: #efefef;
$disabled: #cccccc;

$danger: #dc354b;
$danger-darkest: darken($danger, 25%);
$danger-darker: darken($danger, 10%);
$danger-dark: darken($danger, 5%);
$danger-light: lighten($danger, 5%);
$danger-lighter: lighten($danger, 10%);
$danger-lightest: lighten($danger, 37%);

$danger-bg-light: #ffeef0;

$success: #53b553;
$success-darkest: darken($success, 25%);
$success-darker: darken($success, 10%);
$success-dark: darken($success, 5%);
$success-light: lighten($success, 5%);
$success-lighter: lighten($success, 10%);
$success-lightest: lighten($success, 25%);

$success-bg-light: #e6f9e6;

$darker: #2c323a;
$dark: #939495;
$med: #c7c7c7;
$light: #e0e0e0;
$lighter: #f2f2f2;

$text-perf-light: #b8b8b8;

$dd-shadow: 0px 2px 4px 1px lightgray;
