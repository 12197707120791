@import '~/src/common/styles/vars';

// Header
.audit-type-switch {
  margin: 0 -0.3rem 0 0.1rem !important;
  .form-check-input {
    cursor: pointer;
    width: 2rem !important;
    height: 1.1rem !important;
    border: 1px solid $med;
    &:checked {
      background-color: $perf-secondary-light !important;
      border: $perf-secondary;
    }
    &:focus,
    &:checked {
      box-shadow: none !important;
      outline: none !important;
      border: 1px solid $med;
    }
    &:focus {
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e') !important;
    }
  }
}
.new-audit-btn {
  background: $perf-secondary !important;
  border: none !important;
  &:hover,
  &.show {
    background-color: $perf-secondary-dark !important;
    border: none !important;
    color: white !important;
  }
}
.dropdown-item:active {
  background-color: $light !important;
  color: $dark !important;
}
