@import '../../styles/vars.scss';

.btn.show {
  color: $darker;
}

// Filters
.dropdown-menu.show {
  display: block;
  overflow: auto;
  max-height: 20rem;
  width: fit-content;
  min-width: 100%;
  box-shadow: $dd-shadow;
  z-index: 15;
}
.perf-filters-container {
  a {
    text-decoration: none;
    color: $darker;
  }
}
.perf-dropdown-filter {
  cursor: pointer;
  color: $darker;
  outline: 0 !important;
  font-size: 14px !important;
  white-space: nowrap;
  width: fit-content;
  &:hover {
    background-color: $lighter !important;
    transition: 0.2s;
    color: $darker;
  }
  &:active,
  &:focus {
    background-color: $lighter !important;
    transition: 0.2s;
    color: $darker !important;
  }
}
.perf-filtered {
  color: $darker !important;
}
.perf-dropdown-nenu {
  max-height: '50vh';
  overflow-y: 'auto';
}
.perf-dropdown-item {
  color: $darker !important;
  font-size: 14px !important;
  padding: 6px 10px;
  white-space: nowrap;
  text-decoration: none;
  &:hover {
    background-color: white;
  }
}
.filter-dropdown-no-values {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

// Clear filters button
.clear-filters {
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  padding-left: 0.2rem;
  &:hover {
    background-color: $lighter;
    transition: 0.2s;
  }
}
.clear-filter-x {
  padding: 3px;
  margin-top: -22px;
  margin-left: -12px;
}
.disabled {
  user-select: none;
  color: $disabled !important;
  &:hover {
    background-color: white !important;
  }
}

// Search
.filter-input {
  padding-bottom: 0.2rem;
  flex-grow: 1;
}
