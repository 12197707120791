@import '../../common/styles/vars.scss';

.audit-info-item {
  width: fit-content;
  font-size: 1.4rem;
}

select:disabled {
  cursor: default;
}

#audit-team-pill {
  height: 2rem;
}

.form-control {
  &.read-only {
    background-color: #e9ecef !important;
    cursor: default;
    pointer-events: none;
  }
}

// #region Audit question
/////////////////////////
.question-number,
.ai-number {
  width: 3rem;
  max-width: 3rem;
  color: $med;
}
.answer-btn {
  &:active,
  &:focus-visible {
    background-color: $perf-secondary-dark !important;
    border: 1px solid $perf-secondary-dark !important;
  }
}
.answer-selected {
  background-color: $perf-secondary;
  border: 1px solid $perf-secondary !important;
  &:hover {
    background-color: $perf-secondary-dark !important;
    border: 1px solid $perf-secondary-dark !important;
  }
}

.answer-deselected {
  border: 1px solid $perf-secondary;
  background: none;
  color: $perf-secondary;
  &:hover {
    background: none;
    border: 1px solid $perf-secondary-dark;
    color: $perf-secondary-dark;
  }
}
// #endregion

// Accordion
.accordion-button {
  &:not(.collapsed) {
    background-color: $perf-secondary-lightest;
    color: $perf-secondary-darkest;
  }
  &:focus,
  &:focus-visible {
    box-shadow: none;
    outline: none;
    border: 0.1rem solid $perf-secondary-light;
  }
  &:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234a3766'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
}

.accordion-error {
  border: 1px solid $danger;
  .accordion-button {
    &:not(.collapsed),
    &:focus {
      background-color: $danger-lightest !important;
      color: $danger-darker !important;
      border: none !important;
    }
  }
}

.form-label {
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0;
}

.ca-checkbox {
  &.is-invalid {
    background-color: $danger-lightest;
    color: $danger-darker;
    border: 1px solid $danger-darker;
  }
}

#tooltip-top {
  margin-bottom: 0.2rem;
  .tooltip-inner {
    background-color: white;
    border: 1px solid rgb(214, 214, 214);
    color: black;
  }
  .tooltip-arrow {
    display: none;
  }
}

// #region Responsive
/////////////////////
@media screen and (max-width: 767px) {
  .ai-number {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 990px) {
  .rounded-lg {
    border-radius: 0 !important;
  }
}
// #endregion
