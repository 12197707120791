@import 'common/styles/vars';

// Layout styling
.perf-layout {
  .perf-header {
    height: 4rem;
    background-color: $perf-primary;
    border-bottom: 1px solid $perf-primary-light;

    .nav-control {
      font-size: 1.5rem;

      &.right {
        .header-icon {
          &:hover {
            color: $perf-secondary;
          }

          .perf-tooltip {
            top: 0.2rem;
            right: 2rem;

            font-size: 1rem;

            .tooltip-text {
              background: $darker;
            }
            .tooltip-arrow {
              border-left: 8px solid $darker;
            }
          }
        }
      }
    }
    .logout-btn {
      &:hover {
        color: $perf-secondary !important;
      }
    }
  }

  .perf-body {
    .perf-nav {
      z-index: 30;
      overflow: hidden;
      white-space: nowrap;

      display: flex;
      flex-direction: column;

      padding: 0 !important;

      width: 16rem;

      transition: width 0.3s;
      background-color: $perf-primary;

      color: white;

      &-spacer {
        width: 4.5rem;
      }

      &.sm {
        position: absolute;
        height: calc(100% - 4rem);
      }
      &.xs {
        position: absolute;
        height: calc(100% - 4rem);

        .perf-nav-links {
          flex-grow: unset;
        }

        &.collapsed {
          width: 0;
        }
      }

      &.collapsed {
        width: 4.5rem;

        .perf-nav-links {
          .perf-nav-item {
            &:hover {
              .perf-tooltip {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }
          }
        }

        .perf-nav-footer {
          transition: padding-right 0.3s;
          padding-right: 0;

          .perf-nav-item {
            transition: margin 0.5s;
            margin-right: 30%;
          }
        }
      }

      .perf-nav-links {
        flex-grow: 1;

        display: flex;
        flex-direction: column;

        &.bottom {
          flex-grow: 0;
        }

        .perf-nav-item {
          padding-left: 1.35rem !important;
          padding-right: 1.35rem !important;

          flex-direction: row;
          align-items: center;

          &:hover {
            background-color: $perf-primary-dark;
          }

          &.active {
            background-color: $perf-primary-dark;
            color: $perf-secondary;
          }
        }

        .perf-tooltip {
          left: 4.5rem;

          .tooltip-arrow {
            border-right: 8px solid $darker;
          }
        }
      }

      .perf-nav-footer {
        display: flex;
        flex-direction: row;

        justify-content: flex-end;

        transition: padding-right 0.3s;

        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        padding-right: 0.75rem;

        .perf-nav-item {
          transition: margin 0.4s;

          .toggle-nav {
            display: flex;

            transition: transform 0.5s;

            &.collapsed {
              transition: transform 0.5s;
              transform: scaleX(-1);
            }
          }
        }
      }
    }
  }
}

.nav-bg-overlay {
  z-index: 20;
  background: $bg-overlay;
  top: 0;
  left: 0;

  &.dark {
    background-color: $bg-overlay;
  }
}

.perf-nav-item {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: $perf-secondary;
  }
}

.bi-sm {
  font-size: 1.3rem;
  padding: 0.2rem 1rem 0 0;
}

.perf-tooltip {
  display: none;
  position: absolute;

  .tooltip-text {
    padding: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;

    color: white;
    border-radius: 0.35rem;
    background: $darker;
  }

  .tooltip-arrow {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
}
