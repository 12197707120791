@import '../../styles/vars.scss';

// Search
.search-container {
  padding: 0.4rem 0.6rem 0.4rem 0.6rem;
  font-size: 0.9rem;
  outline: 0;
}
.search-input {
  width: inherit;
  padding-left: 0.5rem;
  &:focus-visible {
    outline: none;
  }
}
.clear-search {
  cursor: pointer;
  &:hover {
    color: gray;
  }
}

.table-data-container {
  overflow: auto;
}

.table-header {
  cursor: pointer;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
  box-shadow: 0 0.02rem 0px $light;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  z-index: 5 !important;
}

.table-header-cell {
  padding-top: 0.6rem !important;
}

.table-cell {
  min-width: fit-content;
  font-size: 0.9rem;
  white-space: nowrap;
}
