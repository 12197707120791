@import '../../styles/vars.scss';

// Show eraser icon on hover
.audit-input {
  .eraser {
    display: none;
    &.mobile {
      display: flex;
    }
  }

  &:hover {
    .eraser {
      display: flex;
    }
  }
}

.audit-dd {
  .audit-dd-toggle {
    border-radius: 0.25rem !important;
    font-size: 0.875rem;
  }
  .is-invalid {
    border: 1px solid $danger !important;
  }
  .read-only {
    background-color: #e9ecef !important;
    cursor: default;
    height: 1.9rem;
  }
}

.search-top-0 {
  top: 0;
}
